<script>
import { SimpleBar } from 'simplebar-vue3';

export default {
  name: 'NotificationPopup',
  props: {
    notifications: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
    },
    cursor: {
      type: Object,
    },
  },
  emits: ['readAll', 'readNotification'],
  components: {
    SimpleBar,
  },
  methods: {
    handleScroll(event) {
      if (this.loading || !this.cursor.next) {
        return;
      }

      if (event.target.scrollHeight - event.target.offsetHeight - event.target.scrollTop < 10) {
        this.$emit('loadNextNotifications');
      }
    },
    onClickNotification(notification) {
      switch (notification.data.key) {
        case 'chat.new_message':
          this.$router.push({ name: 'support' });
          break;
        default:
          return null;
      }
      this.$emit('readNotification', notification.id);
    },
  },
  mounted() {},
  unmounted() {},
};
</script>

<template>
  <div @click.stop>
    <div class="p-3">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="m-0">
            {{ $t('Navbar.Notification.Title') }}
          </h6>
        </div>
        <div class="col-auto">
          <b-spinner v-if="loading" small></b-spinner>
          <a v-else-if="notifications.length" href="#" class="small" @click="$emit('readAll')">
            {{ $t('Navbar.Notification.ViewAll') }}
          </a>
        </div>
      </div>
    </div>
    <SimpleBar @scroll="handleScroll" style="max-height: 280px">
      <a
        v-for="notification in notifications"
        :key="notification.id"
        href="javascript: void(0);"
        class="text-reset notification-item"
        @click="onClickNotification(notification)"
      >
        <div class="d-flex">
          <div class="flex-grow-1">
            <h6 class="mt-0 mb-1">
              {{ $t(`Notifications.${notification.data.key}.title`, notification.data.params || {}) }}
            </h6>
            <div class="font-size-12 text-muted">
              <p v-if="notification.data.key === 'kyc.rejected' && notification.data.comment" class="mb-1">
                {{ notification.data.comment }}
              </p>
              <p v-else class="mb-1">
                {{ $t(`Notifications.${notification.data.key}.message`, notification.data.params || {}) }}
              </p>
              <!-- <p class="mb-0"> -->
              <!-- <i class="mdi mdi-clock-outline"></i> -->
              <!-- 3 min ago -->
              <!-- </p> -->
            </div>
          </div>
        </div>
      </a>
    </SimpleBar>
  </div>
</template>
