<script>
import MetisMenu from 'metismenujs/dist/metismenujs';
import { validationMixin } from '@/mixins/form/validation';

import { menuItems } from './menu';

/**
 * Sidenav component
 */
export default {
  mixins: [validationMixin],

  data() {
    return {
      menuData: null,
      loading: {
        callSupport: false,
      },
    };
  },
  computed: {
    unreadMessages() {
      return this.$store.state.auth?.currentUser?.unread_messages_count;
    },
    menuItemsBadged() {
      const menu = menuItems;

      const support = menu.find((m) => m.label === 'Menu.Support');
      if (support) {
        support.badge = {
          variant: 'primary',
          hide: !this.unreadMessages,
          text: this.unreadMessages,
        };
      }
      return menuItems;
    },
  },
  mounted: function () {
    if (document.getElementById('side-menu')) new MetisMenu('#side-menu');
    var links = document.getElementsByClassName('side-nav-link-ref');
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('mm-active');
        const parent2 = parent.parentElement.closest('ul');
        if (parent2 && parent2.id !== 'side-menu') {
          parent2.classList.add('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('mm-active');
            var childAnchor = parent3.querySelector('.has-arrow');
            var childDropdown = parent3.querySelector('.has-dropdown');
            if (childAnchor) childAnchor.classList.add('mm-active');
            if (childDropdown) childDropdown.classList.add('mm-active');

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== 'side-menu') {
              parent4.classList.add('mm-show');
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== 'side-menu') {
                parent5.classList.add('mm-active');
                const childanchor = parent5.querySelector('.is-parent');
                if (childanchor && parent5.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle('mm-show');
    },
    supportCallback() {
      if (this.loading.callSupport) {
        return;
      }

      this.loading.callSupport = true;
      this.axios
        .post('/support/callback')
        .then(() => {
          this.$notify({
            type: 'success',
            text: this.$t('Notifications.global.PhoneSupport.message'),
          });
        })
        .catch(({ response }) => {
          if (response.status === 429) {
            this.$notify({
              type: 'error',
              text: this.$t('Notifications.global.PhoneSupport.MessageError'),
            });
          } else {
            this.handleResponseErrors(response, {});
          }
        })
        .finally(() => {
          this.loading.callSupport = false;
        });
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItemsBadged">
        <li class="menu-title" v-if="item.isTitle" :key="item.id">{{ $t(item.label) }}</li>

        <li v-if="item.action" :key="item.id" :class="item.class || {}">
          <a href="/#" @click.prevent @click="this[item.action]">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
          </a>
        </li>

        <li v-if="!item.isTitle && !item.isLayout && !item.action" :key="item.id" :class="item.class || {}">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>+
            <span>{{ $t(item.label) }}</span>
            <span :class="`floating-badge rounded-pill bg-${item.badge.variant}`" v-if="item.badge">
              {{ $t(item.badge.text) }}
            </span>
          </a>

          <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <div
              :class="`badge floating-badge rounded-pill bg-${item.badge.variant}`"
              v-if="item.badge && !item.badge.hide"
            >
              {{ $t(item.badge.text) }}
            </div>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge && !item.badge.hide"
              >{{ $t(item.badge.text) }}</span
            >
          </router-link>

          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index" :class="subitem.class || {}">
              <router-link :to="subitem.link" v-if="!hasItems(subitem)" class="side-nav-link-ref">
                {{ $t(subitem.label) }}
              </router-link>
              <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">
                {{ $t(subitem.label) }}
              </a>
              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li v-for="(subSubitem, index) of subitem.subItems" :key="index" :class="subSubitem.class || {}">
                  <router-link :to="subSubitem.link" class="side-nav-link-ref">{{ $t(subSubitem.label) }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
