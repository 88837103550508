<template>
  <svg v-if="!crossed" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 2.5C15.4499 2.5 18.7818 9.33333 18.9405 9.66667C19.0198 9.83333 19.0198 10.1667 18.9405 10.3333C18.7818 10.6667 15.4499 17.5 9.5 17.5C3.89964 17.5 0.618682 11.446 0.120205 10.5262L0.120195 10.5262C0.0890885 10.4688 0.068819 10.4314 0.059499 10.4167C-0.019833 10.1667 -0.019833 9.91667 0.059499 9.66667C0.218163 9.33333 3.5501 2.5 9.5 2.5ZM1.64614 10C2.43946 11.3333 5.21607 15.8333 9.5 15.8333C13.7839 15.8333 16.5605 11.3333 17.3539 10C16.5605 8.66667 13.7839 4.16667 9.5 4.16667C5.21607 4.16667 2.36013 8.66667 1.64614 10ZM9.5 6.66667C7.7547 6.66667 6.32672 8.16667 6.32672 10C6.32672 11.8333 7.7547 13.3333 9.5 13.3333C11.2453 13.3333 12.6733 11.8333 12.6733 10C12.6733 8.16667 11.2453 6.66667 9.5 6.66667ZM7.91336 10C7.91336 10.9167 8.62735 11.6667 9.5 11.6667C10.3727 11.6667 11.0866 10.9167 11.0866 10C11.0866 9.08333 10.3727 8.33333 9.5 8.33333C8.62735 8.33333 7.91336 9.08333 7.91336 10Z"
      fill="black"
    />
    <mask
      id="mask0_10787_198939"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="2"
      width="19"
      height="16"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 2.5C15.4499 2.5 18.7818 9.33333 18.9405 9.66667C19.0198 9.83333 19.0198 10.1667 18.9405 10.3333C18.7818 10.6667 15.4499 17.5 9.5 17.5C3.89964 17.5 0.618682 11.446 0.120205 10.5262L0.120195 10.5262C0.0890885 10.4688 0.068819 10.4314 0.059499 10.4167C-0.019833 10.1667 -0.019833 9.91667 0.059499 9.66667C0.218163 9.33333 3.5501 2.5 9.5 2.5ZM1.64614 10C2.43946 11.3333 5.21607 15.8333 9.5 15.8333C13.7839 15.8333 16.5605 11.3333 17.3539 10C16.5605 8.66667 13.7839 4.16667 9.5 4.16667C5.21607 4.16667 2.36013 8.66667 1.64614 10ZM9.5 6.66667C7.7547 6.66667 6.32672 8.16667 6.32672 10C6.32672 11.8333 7.7547 13.3333 9.5 13.3333C11.2453 13.3333 12.6733 11.8333 12.6733 10C12.6733 8.16667 11.2453 6.66667 9.5 6.66667ZM7.91336 10C7.91336 10.9167 8.62735 11.6667 9.5 11.6667C10.3727 11.6667 11.0866 10.9167 11.0866 10C11.0866 9.08333 10.3727 8.33333 9.5 8.33333C8.62735 8.33333 7.91336 9.08333 7.91336 10Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10787_198939)">
      <rect width="19" height="20" fill="#81828D" />
    </g>
  </svg>

  <svg v-else width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.2083 19C18.4458 19 18.6042 18.9208 18.7625 18.7625C19.0792 18.4458 19.0792 17.9708 18.7625 17.6542L1.34583 0.2375C1.02917 -0.0791667 0.554167 -0.0791667 0.2375 0.2375C-0.0791667 0.554167 -0.0791667 1.02917 0.2375 1.34583L3.64167 4.75C2.21667 5.9375 0.95 7.44167 0.0791667 9.10417C-3.72529e-09 9.34167 -3.72529e-09 9.57917 0.0791667 9.81667C0.2375 10.1333 3.5625 16.625 9.5 16.625C11.1625 16.625 12.7458 16.15 14.0917 15.2L17.6542 18.7625C17.8125 18.9208 17.9708 19 18.2083 19ZM7.67917 2.6125C7.20417 2.69167 6.96667 3.0875 7.04583 3.5625C7.20417 3.95833 7.6 4.19583 7.99583 4.275C8.47083 4.19583 9.025 4.11667 9.5 4.11667C13.775 4.11667 16.625 8.39167 17.3375 9.65833C16.9417 10.3708 16.4667 11.0042 15.9125 11.6375C15.5958 11.9542 15.675 12.4292 15.9917 12.7458C16.0708 12.825 16.3083 12.9042 16.4667 12.9042C16.7042 12.9042 16.9417 12.7458 17.1 12.5875C17.8125 11.7167 18.4458 10.8458 18.9208 9.89583C19 9.65833 19 9.42083 18.9208 9.18333C18.7625 8.86667 15.4375 2.375 9.5 2.375C8.86667 2.375 8.23333 2.45417 7.67917 2.6125ZM7.8375 9.57917C7.8375 9.975 7.99583 10.3708 8.3125 10.6875C8.7875 11.0833 9.34167 11.1625 9.89583 11.0042L7.91667 9.025C7.91667 9.15575 7.89267 9.2625 7.87111 9.35847C7.85349 9.43683 7.8375 9.508 7.8375 9.57917ZM11.0833 12.2708L12.9833 14.0917C11.875 14.725 10.6875 15.0417 9.5 15.0417C5.225 15.0417 2.375 10.7667 1.6625 9.5C2.53333 8.075 3.5625 6.8875 4.75 5.85833L6.72917 7.8375C6.4125 8.39167 6.25417 9.025 6.25417 9.65833C6.33333 10.5292 6.65 11.3208 7.28333 11.875C7.8375 12.4292 8.62917 12.7458 9.42083 12.7458C9.975 12.7458 10.6083 12.5875 11.0833 12.2708Z"
      fill="black"
    />
    <mask
      id="mask0_10787_198940"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="19"
      height="19"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2083 19C18.4458 19 18.6042 18.9208 18.7625 18.7625C19.0792 18.4458 19.0792 17.9708 18.7625 17.6542L1.34583 0.2375C1.02917 -0.0791667 0.554167 -0.0791667 0.2375 0.2375C-0.0791667 0.554167 -0.0791667 1.02917 0.2375 1.34583L3.64167 4.75C2.21667 5.9375 0.95 7.44167 0.0791667 9.10417C-3.72529e-09 9.34167 -3.72529e-09 9.57917 0.0791667 9.81667C0.2375 10.1333 3.5625 16.625 9.5 16.625C11.1625 16.625 12.7458 16.15 14.0917 15.2L17.6542 18.7625C17.8125 18.9208 17.9708 19 18.2083 19ZM7.67917 2.6125C7.20417 2.69167 6.96667 3.0875 7.04583 3.5625C7.20417 3.95833 7.6 4.19583 7.99583 4.275C8.47083 4.19583 9.025 4.11667 9.5 4.11667C13.775 4.11667 16.625 8.39167 17.3375 9.65833C16.9417 10.3708 16.4667 11.0042 15.9125 11.6375C15.5958 11.9542 15.675 12.4292 15.9917 12.7458C16.0708 12.825 16.3083 12.9042 16.4667 12.9042C16.7042 12.9042 16.9417 12.7458 17.1 12.5875C17.8125 11.7167 18.4458 10.8458 18.9208 9.89583C19 9.65833 19 9.42083 18.9208 9.18333C18.7625 8.86667 15.4375 2.375 9.5 2.375C8.86667 2.375 8.23333 2.45417 7.67917 2.6125ZM7.8375 9.57917C7.8375 9.975 7.99583 10.3708 8.3125 10.6875C8.7875 11.0833 9.34167 11.1625 9.89583 11.0042L7.91667 9.025C7.91667 9.15575 7.89267 9.2625 7.87111 9.35847C7.85349 9.43683 7.8375 9.508 7.8375 9.57917ZM11.0833 12.2708L12.9833 14.0917C11.875 14.725 10.6875 15.0417 9.5 15.0417C5.225 15.0417 2.375 10.7667 1.6625 9.5C2.53333 8.075 3.5625 6.8875 4.75 5.85833L6.72917 7.8375C6.4125 8.39167 6.25417 9.025 6.25417 9.65833C6.33333 10.5292 6.65 11.3208 7.28333 11.875C7.8375 12.4292 8.62917 12.7458 9.42083 12.7458C9.975 12.7458 10.6083 12.5875 11.0833 12.2708Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10787_198940)">
      <rect width="19" height="19" fill="#6E6B7B" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    crossed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
