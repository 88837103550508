export const menuItems = [
  {
    id: 1,
    label: 'Menu.Menu',
    isTitle: true,
  },
  {
    id: 2,
    label: 'Menu.Home',
    icon: 'bx-home-circle',
    link: '/',
  },
  {
    id: 3,
    isLayout: true,
  },
  {
    id: 4,
    label: 'Menu.Trading',
    isTitle: true,
  },
  {
    id: 5,
    label: 'Menu.OpenAccount',
    icon: 'bx-wallet',
    link: '/open-account',
  },
  {
    id: 6,
    label: 'Menu.Accounts',
    icon: 'bx-user',
    link: '/accounts',
  },
  {
    id: 7,
    isLayout: true,
  },
  {
    id: 8,
    label: 'Menu.Transfers',
    isTitle: true,
  },
  {
    id: 9,
    label: 'Menu.Deposit',
    icon: 'bx-dollar-circle',
    link: '/deposit',
  },
  {
    id: 10,
    label: 'Menu.Withdraw',
    icon: 'bx-send',
    link: '/withdraw',
  },
  {
    id: 11,
    label: 'Menu.Swap',
    icon: 'bx-repost',
    link: '/swap',
  },
  {
    id: 12,
    label: 'Menu.TransfersReport',
    icon: 'bx-pie-chart-alt-2',
    link: '/transfers-report',
  },
  {
    id: 13,
    label: 'Profile.Verification.Title',
    icon: 'bx-check-circle',
    link: '/profile/verification',
  },
  {
    id: 14,
    label: 'Menu.Support',
    icon: 'bx-message',
    link: '/support',
  },
  {
    id: 15,
    label: 'Menu.TelephoneSupport',
    icon: 'bx-phone-outgoing',
    action: 'supportCallback',
    class: 'menuSupportItem'
  },
  // {
  //   id: 13,
  //   isLayout: true,
  // },
  // {
  //   id: 14,
  //   label: 'Menu.Support',
  //   isTitle: true,
  // },
  // {
  //   id: 15,
  //   label: 'Menu.Chat',
  //   icon: 'bx-chat',
  //   link: '/chat',
  // },
];
