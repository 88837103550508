<script>
export default {
  data() {
    return {
      appName: this.$getConfigValue('appName', 'VUE_APP_NAME'),
    };
  },
};
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ new Date().getFullYear() - 1 }} - {{ new Date().getFullYear() }} © {{ appName }}.</div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
