<script>
import { authComputed } from '@/state/helpers';
import NotificationPopup from '@/components/NotificationsPopup';
import { mapState, mapGetters } from 'vuex';
import brandMixin from '@/mixins/brand.mixin';
import { validationMixin } from '@/mixins/form/validation';
import LanguageSelector from '@/components/LanguageSelector';
import EyeIcon from '@/components/icons/EyeIcon';
import { mapActions } from 'vuex';

export default {
  mixins: [brandMixin, validationMixin],
  components: {
    NotificationPopup,
    LanguageSelector,
    EyeIcon,
  },
  data() {
    return {
      text: null,
      flag: null,
      value: null,
      tradingUrl: this.$getConfigValue('tradeUrl', 'VUE_APP_TRADE_URL'),
      notifications: [],
      accounts: [],
      newNotifications: 0,
      notificationsCursor: null,
      notificationFilters: {
        unread: 1,
        per_page: 10,
      },
      loading: {
        notifications: false,
      },
    };
  },
  methods: {
    ...mapActions('auth', ['toggleVisibleBalnce']),
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    openNotifications() {
      this.fetchNotifications();
    },
    fetchNotifications(nextPage = false) {
      if (!nextPage) {
        this.notifications = [];
      }

      this.loading.notifications = true;

      const params = {
        ...this.notificationFilters,
      };

      if (nextPage && this.notificationsCursor.next) {
        params.cursor = this.notificationsCursor.next;
      }

      this.axios
        .get('/notifications', { params })
        .then(({ data, cursor }) => {
          this.notifications = nextPage ? [...this.notifications, ...data] : data;
          this.notificationsCursor = cursor;
          this.newNotifications = cursor.count;
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, {});
        })
        .finally(() => {
          this.loading.notifications = false;
        });
    },
    readNotification(id) {
      this.loading.notifications = true;

      this.axios
        .put(`/notifications/${id}`)
        .then(() => {
          this.notifications = this.notifications.filter((n) => n.id !== id);
          this.newNotifications--;
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, {});
        })
        .finally(() => {
          this.loading.notifications = false;
        });
    },
    readAllNotifications() {
      this.loading.notifications = true;

      this.axios
        .put(`/notifications/`)
        .then(() => {
          this.$refs.notificationDropdown.hide(true);
          this.notifications = [];
          this.newNotifications = 0;
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, {});
        })
        .finally(() => {
          this.loading.notifications = false;
        });
    },
    fetchAccounts() {
      this.axios.get('/accounts').then(({ data }) => {
        this.accounts = data;
      });
    },
    unsubscribeFromChannel() {
      this.$broadcasting.echo
        .private(`clients.${this.currentUser.id}.branding`)
        .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', this.listenNotifications);
    },
    listenNotifications(notification) {
      this.newNotifications++;

      if (notification.key === 'chat.new_message' && this.$route.name !== 'support') {
        this.$store.commit('auth/SET_CURRENT_USER', {
          ...this.$store.state.auth.currentUser,
          unread_messages_count: this.$store.state.auth.currentUser.unread_messages_count + 1,
        });
        this.$notify({
          title: this.$t('Notifications.chat.new_message.title'),
          text: this.$t('Notifications.chat.new_message.message'),
        });
      }
    },
  },
  computed: {
    ...authComputed,
    ...mapState('broadcasting', ['echo']),
    ...mapState('auth', ['visibleBalance']),
    ...mapGetters('auth', ['userBalanceExchanged']),
    balanceExchanged() {
      return (this.balance.balance_full * this.balanceRate).toFixed(2);
    },
  },
  mounted() {
    this.fetchNotifications();
    this.fetchAccounts();
    this.$broadcasting.echo.private(`clients.${this.currentUser.id}.branding`).notification(this.listenNotifications);
  },
  unmounted() {
    this.unsubscribeFromChannel();
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box px-1">
          <router-link to="/" class="logo">
            <span class="logo-sm">
              <img :src="$getConfigValue('logoPath', 'VUE_APP_LOGO_URL')" alt height="30" />
            </span>
            <span class="logo-lg">
              <img :src="$getConfigValue('logoFullPath', 'VUE_APP_LOGO_FULL_URL')" alt height="30" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <div class="header-balance">
          <span class="header-balance__value">
            {{ currentUser.base_currency.symbol }}<template v-if="visibleBalance">{{ userBalanceExchanged }}</template
            ><template v-else>***</template>
            <span class="hide-balance-button">
              <EyeIcon :crossed="!visibleBalance" @click="toggleVisibleBalnce" />
            </span>
          </span>
          <span class="header-balance__text">{{ $t('Dashboard.TotalOnRealAccounts') }}</span>
        </div>
      </div>

      <div>
        <b-button
          type="button"
          class="btn btn-primary text-dark trading-btn my-auto d-flex align-items-center"
          :href="tradingUrl"
          target="_blank"
        >
          <i class="fa fa-chart-line me-md-2 trading-btn-icon"></i>

          <span class="d-none d-md-inline text-uppercase">
            {{ $t('Common.TradingTerminal') }}
          </span>
        </b-button>
      </div>

      <div class="d-flex">
        <!-- <b-dropdown
          class=" d-inline-block d-lg-none ms-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown> -->

        <LanguageSelector />

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
          ref="notificationDropdown"
          @show="openNotifications"
        >
          <template v-slot:button-content>
            <i v-if="newNotifications > 0" class="bx bx-bell bx-tada"></i>
            <i v-else class="bx bx-bell"></i>
            <span v-if="newNotifications > 0" class="badge bg-danger rounded-pill">{{ newNotifications }}</span>
          </template>

          <NotificationPopup
            :notifications="notifications"
            :loading="loading.notifications"
            :cursor="notificationsCursor"
            @readNotification="readNotification"
            @readAll="readAllNotifications"
            @loadNextNotifications="fetchNotifications(true)"
          />
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item" menu-class="dropdown-menu-end">
          <template v-slot:button-content>
            <span class="rtl-row-reverse">
              <i class="bx bx-user font-size-22 align-middle"></i>
              <span class="d-none d-xl-inline-block ms-1">
                <div>{{ currentUser.first_name }}</div>
              </span>
              <i class="mdi mdi-chevron-down"></i>
            </span>
          </template>
          <b-dropdown-item :to="{ name: 'profile' }">
            <div class="rtl-row-reverse">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t('Navbar.Dropdown.List.Profile') }}
            </div>

            <div class="text-end rtl-row-reverse">
              <span v-if="currentUser.verified" class="badge bg-success rounded-pill font-size-8">{{
                $t('Navbar.Dropdown.Verified')
              }}</span>
              <span v-else class="badge bg-warning rounded-pill font-size-8">{{
                $t('Navbar.Dropdown.Unverified')
              }}</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'accounts' }" class="rtl-row-reverse">
            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            {{ $t('Navbar.Dropdown.List.MyWallet') }}
          </b-dropdown-item>
          <b-dropdown-item class="rtl-row-reverse" :to="{ name: 'settings' }">
            <!-- <span class="badge bg-success float-end">11</span> -->
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            {{ $t('Navbar.Dropdown.List.Settings') }}
          </b-dropdown-item>

          <router-link to="/logout" class="dropdown-item text-danger rtl-row-reverse">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ $t('Navbar.Dropdown.List.Logout') }}
          </router-link>
        </b-dropdown>

        <!--        <div class="dropdown d-inline-block">-->
        <!--          <button-->
        <!--            type="button"-->
        <!--            class="btn header-item noti-icon right-bar-toggle toggle-right"-->
        <!--            @click="toggleRightSidebar"-->
        <!--          >-->
        <!--            <i class="bx bx-cog bx-spin toggle-right"></i>-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header-balance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;

  &__value {
    color: #05051d;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__text {
    color: #81828d;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -6px;
  }
}

@media (max-width: 768px) {
  .header-balance {
    margin-left: -8px;
    margin-right: 8px;
    white-space: nowrap;

    &__value {
      font-size: 12px;
    }

    &__text {
      display: none;
    }
  }
}

.hide-balance-button {
  cursor: pointer;
  margin-left: 6px;
  margin-right: 6px;
  z-index: 10;
  position: relative;
}
</style>

<style scoped>
.app-rtl .navbar-brand-box {
  text-align: right;
  padding-right: 1.5rem !important;
}
</style>
