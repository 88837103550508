<script>
import router from '@/router';
import { authComputed, layoutComputed } from '@/state/helpers';

import NavBar from '@/components/nav-bar';
import SideBar from '@/components/side-bar';
import Footer from '@/components/footer';

/**
 * Vertical layout
 */
export default {
  components: { NavBar, SideBar, Footer },
  data() {
    return {
      type: this.$store ? this.$store.state.layout.leftSidebarType : null || null,
      width: this.$store ? this.$store.state.layout.layoutWidth : null || null,
      isMenuCondensed: false,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    isTradingPage() {
      return this.$route.name === 'trading';
    },
    isChatPage() {
      return this.$route.name === 'support';
    },
  },
  created: () => {
    document.body.removeAttribute('data-layout', 'horizontal');
    document.body.removeAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-layout-size', 'boxed');
    document.body.classList.remove('auth-body-bg');
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable');

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
        });
        document.body.classList.toggle('vertical-collpsed');
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable');
        });
        document.body.classList.remove('vertical-collpsed');
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    handleLoader() {
      if (this.loader === true) {
        document.getElementById('preloader').style.display = 'block';
        document.getElementById('status').style.display = 'block';
      } else {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }
    },
  },
  mounted() {
    this.handleLoader();
  },
  watch: {
    loader() {
      this.handleLoader();
    },
    currentUser: {
      immediate: true,
      handler(user) {
        if (!user?.id) this.$store.dispatch('layout/changeLoaderValue', { loader: true });
        else if (user.id && this.loader) this.$store.dispatch('layout/changeLoaderValue', { loader: false });
      },
    },
  },
};
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <NavBar />
      <SideBar v-show="!isTradingPage" :is-condensed="isMenuCondensed" :type="leftSidebarType" :width="layoutWidth" />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content" :class="{ 'hide-sidebar': isTradingPage }">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer v-show="!isTradingPage && !isChatPage" />
      </div>
    </div>
  </div>
</template>
